import $ from 'jquery';
import Foundation from "foundation-sites";
import getFormValuesByParent from "../helper_functions/getFormValuesByParent";
import initSelectize from "../helper_functions/initSelectize";
import reFetchProduktFinderSelectizeOptions from "../helper_functions/reFetchProduktFinderSelectizeOptions";

const lang = window.pimcore_locale;

const ajaxUrl = `/boeckmann/api/${lang}/product-finder/filter`

export default function () {
    const wrap = $('.product-finder-filter-wrap');

    if(wrap.length <= 0) {
        return false;
    }
    $('#filter').parents('form').append('<input type="hidden" id="distance" name="h" value="" />');
    addResetFilterSelect(wrap)

    if (Foundation.MediaQuery.is('medium down')) {
        var stickyMargin = 7;
        if($(window).width() > 639) {
            stickyMargin = 9;
        }
        var sticky = new Foundation.Sticky(wrap.find('.filter-control'), {
            stickyOn: 'small',
            stickTo: 'top',
            marginTop: stickyMargin,
            topAnchor: 'list',
            btmAnchor: 'list:bottom',
        });

        wrap.on('click', '.tabs-title.is-active a', function (e) {
            e.preventDefault();
            let stateClass = 'tabs-opened'
            let positionClass = stateClass +'--top';
            if (sticky.$element.hasClass('is-at-bottom')) {
                positionClass = stateClass +'--bottom';
            }
            $(this).parents('.tabs').toggleClass(stateClass)
            $(this).parents('.tabs').toggleClass(positionClass)
        })

        $('body').on('click', '[data-filter-toggle]', function (e) {
            e.preventDefault();
            $('body').toggleClass('product-finder-opened')
            return false;
        });
    } else {
        if (readParams('h')) {
            let distanceTop = readParams('h');
            $('html,body').scrollTop(distanceTop);
        }
        wrap.on('click', '.tabs-title a, .action-bar a', function (e) {
            let newParamUrl = $(this).attr('href') + '&h=' + $(window).scrollTop();
            window.location = newParamUrl;
            e.preventDefault();
        });
        wrap.on('click', 'button[type="submit"]', function (e) {
            $('#distance').val($(window).scrollTop());
        });
    }

    wrap.on('change', 'select', function() {
        const data = getFormValuesByParent(wrap);
        const form = $('form');

        form.addClass('loading');

        const request = $.ajax({
            url: ajaxUrl,
            method: "GET",
            data: data,
            dataType: "html"
        });

        request.done(function( msg ) {
            wrap.html($(msg))
            initSelectize(wrap)
            addResetFilterSelect(wrap)
            form.removeClass('loading');
        });

        request.fail(function( jqXHR, textStatus ) {
            form.removeClass('loading');
            console.error( "Request failed: " + textStatus );
        });
    });
}

function addResetFilterSelect(parent) {
    const data = getFormValuesByParent(parent);

    parent.find('select.selectize-origin-select').each(function () {
        let select = $(this);
        let selectize = this.selectize;

        reFetchProduktFinderSelectizeOptions(this, data)

        if (select.val() !== '' && !selectize.$control.parent().hasClass('multi')) {
            selectize.$control.hide();

            const parent = select.parent();
            const resetButton = $('<div class="selectize-control product-finder-filter-wrap__select_filter_reset"><div class="selectize-input"><input value="'+ selectize.getItem(select.val()).text() +'" /></div></div>');

            resetButton.one('click', function () {
                selectize.clear();
            });
            parent.append(resetButton)
        }
    });
}

function readParams (paramName) {
    var param = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);
    if (param == null) {
        return null;
    }
    return decodeURI(param[1]) || 0;
}
