import $ from 'jquery';

$( document ).ready(function() {
    jQuery('#order_sortOrder').change(function() {
        jQuery(this).parents('form').submit();
    });

    jQuery('#order_produktfamilie').change(function() {
        jQuery(this).parents('form').submit();
    });

});
